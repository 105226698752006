import React from 'react';
import { Table,Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import Button from 'antd/es/button';
import Register from './poEdit_modal';
import DataTable from '../../../components/data-display/table/DataTable';
import moment from 'moment';
import edit from "../../../images/new/edit_icon_blue.svg"
import {TextCapitalize,TextUpperCase,NumToStr } from '../../../utils/helper'; 
const List = (props) => {
  const tableColumns = [
    { name: 'PO NO.', label: 'PO No.', options: { filter: true, sort: true,customBodyRender: value =>TextUpperCase(value) } },
    {
      name: 'CUSTOMER NAME',
      label: 'Customer Name',
      options: { filter: true, sort: true,customBodyRender: value =>TextCapitalize(value) },
    },
    {
      name: 'PO VALUE',
      label: 'PO Value',
      options: { filter: true, sort: true,customBodyRender: value =>'$'+NumToStr(value,0) },
    },
    {
      name: 'ISSUE DATE',
      label: 'Issue Date',
      options: { filter: true, sort: true },
    },
    {
      name: 'EXPIRY DATE',
      label: 'Expiry Date',
      options: { filter: true, sort: true },
    },
    {
      name: "",
      label: 'Action',
      options: { filter: true, sort: true },
      // render: (_, elm) => (
      //   <div className="text-right">
      //     <Tooltip title="Edit">
      //       <Button
      //         type="link"
      //         className="mr-2"
      //         icon={<EditOutlined />}
      //         // onClick={() => {
      //         //   props.EditModeOn(props?.dt[elm.key]);
      //         // }}
      //         size="small"
      //       />
      //     </Tooltip>
      //   </div>
      // ),
      
    },
  ];
  const columns = [
    {
      title: 'PO NO.',
      dataIndex: 'poNo',
      width: 100,
      sorter: {
        compare: (a, b) => {
          a = a.poNo.toLowerCase();
          b = b.poNo.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'CUSTOMER NAME',
      dataIndex: 'name',
      width: 170,
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'PO VALUE',
      dataIndex: 'po_value',
      width: 150,
      sorter: {
        compare: (a, b) => {
          a = a.po_value.toLowerCase();
          b = b.po_value.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (_, root) => <span>{`$ ${root.po_value}`}</span>,
    },
    {
      title: 'ISSUE DATE',
      dataIndex: 'issue',
      width: 150,
      sorter: {
        compare: (a, b) => {
          a = a.issue.toLowerCase();
          b = b.issue.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'EXPIRY DATE',
      dataIndex: 'expiry',
      width: 150,
      sorter: {
        compare: (a, b) => {
          a = a.expiry.toLowerCase();
          b = b.expiry.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
  ];
  const data = [];
  const tableData = [];
  props.dt &&
    props.dt.map((item) => {
      data.push({
        key: item.po_id,
        poNo: item.po_no,
        name: item.customer_detail.name,
        po_value: item.po_value,
        issue: moment(item.issue_date).format('MM/DD/YYYY'),
        expiry: moment(item.expiry_date).format('MM/DD/YYYY'),
      });
      tableData.push([
        item.po_no,
        item.customer_detail.name,
        item.po_value,
        moment(item.issue_date).format('MM/DD/YYYY'),
        moment(item.expiry_date).format('MM/DD/YYYY'),
         <Register data={item} callPurchaseListApi={props.callPurchaseListApi}
          Button={ <div style={{cursor:'pointer'}}>
                   <img src={edit} alt='icon' />
                  {/* <span style={{color:'#38B6FF',marginLeft:'7px'}}>Edit</span>  */}
             </div>}
          title="PO EDIT"
          custom={true}
          // className="wash_po_add_edit_btn"
          />
      ]);
    });
  let pageLength = data.length;
  return (
    <div>
      {(() => {
        if (true) {
          return (
            <div>
              {/* <Table
              columns={columns}
              dataSource={data}
              pagination={{pageSize: 20}}
              // scroll={{y: 800}}
            /> */}
              <DataTable
                loader={props.loading}
                header={tableColumns}
                data={tableData}
                rowKey="id"
              />
            </div>
          );
        }
      })()}
    </div>
  );
};
export default List;
