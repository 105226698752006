import React, { useState, useEffect } from 'react';
import AppLayout from '../../components/layout';
import List from './comp/List';
//import Button from '../../components/button/Button';
import Date from './comp/Date';
import IssueDate from './comp/issueDate';
import InputForm from './comp/InputForm';
import SelectForm from './comp/SelectForm';
import MultiSelectServices from './comp/MultiSelectServices';
import moment from 'moment';
import './Po.css';
import API from '../../api';
import { Routes } from '../../utils/route';
import { message, Button, Spin, Divider } from 'antd';
import { usePosition } from '../../utils/usePosition';
import { IndustryType } from '../../utils/helper';
import PageHeader from '../../components/pageHeader/PageHeader';
import Plus from "../../images/new/plus_icon.svg"
import Minus from "../../images/new/minus_icon.svg"
const Po = () => {
  const {
    purchaseList,
    customerList,
    fetchFoodList,
    getPOServiceList,
    checkponumber,
    addPurchaseOrder,
  } = API;
  const [data, setState] = useState([]);
  const { latitude, longitude } = usePosition();
  const [customerListData, setCustomerListData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState();

  const [serviceList, setServiceList] = useState([]);
  const [selectedServiceList, setSelectedServiceList] = useState([]);

  const [poNumber, setPoNumber] = useState(null);
  const [poNumberCheck, setPoNumberCheck] = useState(true);

  const [issueDate, setIssueDate] = useState(moment());
  const [expireDate, setExpireDate] = useState(moment());

  const [poValue, setPoValue] = useState(null);
  const [remark, setRemark] = useState('');
  const [loading, setLoading] = useState(true);
  const [load, setload] = useState(true);
  const [apiLoading, setApiLoading] = useState(false);
  const [toggle, setToggle] = useState(false);

  const handleChange = (value) => {
    setSelectedCustomer(value);
  };

  const handleChangeServices = (value) => {
    setSelectedServiceList(value);
  };

  useEffect(() => {
    const callback = (val) => {
      if (val === true) {
        setLoading(false);
        callPurchaseListApi();
        callCustomerListApi();
        callPOServiceListApi();
      }
    };
    Routes('manager', callback);
  }, []);

  const callPurchaseListApi = () => {
    purchaseList()
      .then((resp) => {
        setload(false);
        setState(resp.data.data);
      })
      .catch((er) => {
        setload(false); 
      });
  };

  const callCustomerListApi = () => {
    customerList()
      .then((resp) => {
        resp.data.data&&resp.data.data.sort((a,b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase()> a.name.toUpperCase()) ? -1 : 0))
        setCustomerListData(resp.data.data);
      })
      .catch((er) => {
        console.log('error in customerList API', er);
      });
  };

  const callPOServiceListApi = () => {
    if(IndustryType()!=="Food_Grade"){
    getPOServiceList()
      .then((resp) => {
        setServiceList(resp.data.data);
      })
      .catch((er) => {
        console.log('error in callPOServiceListApi API', er);
      });
    }else{
      fetchFoodList()
      .then((resp) => {
        setServiceList(resp.data.data);
      })
      .catch((er) => {
        console.log('error in callPOServiceListApi API', er);
      });
    }
  };

  const checkPONumber = () => {
    checkponumber(poNumber, selectedCustomer)
      .then((resp) => {
        if (resp.data.success) {
          setPoNumberCheck(resp.data.data);
        }
      })
      .catch((er) => {
        console.log('error in checkponumber API', er);
      });
  };

  const validate = async () => {
    //var re = /0/g;
    //var ret = re.test('0 there!');
    //var iu = RegExp.lastMatch;
    //var fil = RegExp['$&'];

    if (!selectedCustomer) {
      message.warning({ content: `Please Select Customer`, duration: 2 });
      return false;
    }
    if (poNumber === null) {
      message.warning({ content: `Please enter PO number`, duration: 2 });
      return false;
    } else if (poValue === null) {
      message.warning({ content: `Please enter PO value`, duration: 2 });
      return false;
    } else if (poValue == 0) {
      message.warning({ content: `PO value cannot be 0.`, duration: 2 });
      return false;
    } else if (!poNumberCheck) {
      /*else if (fil === "0") {
      message.warning({ content: `PO value must not start with 0.`, duration: 2 });
      return false;
    }*/
      message.warning({ content: `PO number must be unique`, duration: 2 });
      return false;
    } else {
      return true;
    }
  };

  const createPuchaseOrder = async () => {
    const isValid = await validate();
    if (isValid) {
      setApiLoading(true);
      let params = {
        client_cust_id: selectedCustomer,
        services: selectedServiceList,
        po_no: poNumber,
        po_value: parseFloat(poValue),
        remark: `${remark}`,
        issue_date: `${issueDate.format()}`,
        expiry_date: `${expireDate.format()}`,
        coordinates: [latitude ? latitude : 0.0, longitude ? longitude : 0.0],
      };
      addPurchaseOrder(params)
        .then((resp) => {
          if (resp.data.status) {
            callPurchaseListApi();
            setApiLoading(false);
            setPoNumberCheck(true);
            clearInputs();
            message.success({ content: `${resp.data.message}`, duration: 2 });
          } else {
            message.success({ content: `${resp.data.message}`, duration: 2 });
            setApiLoading(false);
          }
          // setServiceList(resp.data.data)
        })
        .catch((er) => {
          console.log('error in addPurchaseOrder API', er.message);

          setApiLoading(false);
          message.warning({ content: `Error adding PO`, duration: 2 });
        });
    }
  };

  const clearInputs = () => {
    setSelectedCustomer();
    setSelectedServiceList([]);
    setPoNumber(null);
    setPoNumberCheck(true);
    setIssueDate(moment());
    setExpireDate(moment());
    setPoValue(null);
    setRemark('');
  };
  const toggleHandler=()=>{
    setToggle(!toggle)
  }
  if (!loading) {
    return (
      <AppLayout>
        <PageHeader title="PO list"/>
        {/* <h3 style={{marginBottom: 35}}>PO LIST</h3> */}
        {/* <Divider /> */}
        <List dt={data} loading={load} callPurchaseListApi={callPurchaseListApi} />
        <section className="purchase">
          <div className='purchase_head' onClick={toggleHandler} style={{borderBottom:toggle?'1px solid #0000001A':''}}>
          <span>create Puchase Order</span>
          <img src={toggle?Minus:Plus} alt="images"/>
          </div>
          <div className={toggle?'purchase_toggle_show':'purchase_toggle_hide'}>
              <SelectForm
                value={selectedCustomer}
                customerList={customerListData}
                className="purchase_1"
                label="Customer"
                ple="single"
                pls="Please Select Customer"
                handleChange={handleChange}
              />
              <InputForm
                value={poNumber}
                isUnique={poNumber === null ? true : poNumberCheck}
                checkPONumber={(e) => checkPONumber()}
                onChangePo={(value) => {
                  setPoNumber(value);
                }}
                className="purchase_1"
                label="PO NO."
                pls="Enter Job Site Details"
              />
              <InputForm
                value={poValue}
                isUnique={true}
                checkPONumber={() => console.log('')}
                onChangePo={(value) => {
                  setPoValue(value);
                }}
                className="purchase_1"
                label="PO Values"
                pls="ENTER PO VALUE"
              />

                <div className="purchase_1">
                  <label>PO Issue Date</label>
                  <IssueDate
                    value={issueDate}
                    expireDate={expireDate}
                    onChangeDate={(issueDate) =>
                    issueDate && setIssueDate(issueDate)}
                  />
                </div>

                <div className="purchase_1">
                  <label>PO Expire Date</label>
                  <Date
                    value={expireDate}
                    issueDate={issueDate}
                    onChangeDate={(expiryDate) =>
                      expiryDate && setExpireDate(expiryDate)}
                  />
                </div>
                <MultiSelectServices
                value={selectedServiceList}
                serviceList={serviceList}
                className="purchase_1"
                label="Services"
                ple="multiple"
                pls="Please Select Services"
                handleChange={(value) => handleChangeServices(value)}
              />
              
              <div className="purchase_2">
                <label>Remarks</label><br/>
                <textarea
                  value={remark}
                  placeholder="Type Here..."
                  onChange={(e) => setRemark(e.target.value)}
                />
              </div>
            
          <div className="purchase_3">
            <Button
              style={{background:"#38B6FF",borderRadius:"5px",color:"#fff",width:"100px"}}
              disabled={apiLoading}
              loading={apiLoading}
              onClick={() => createPuchaseOrder()}
            >
              Submit
            </Button>
          </div>

          </div>
        </section>
      </AppLayout>
    );
  } else {
    return (
      <div className="spinner-main">
        <Spin />
      </div>
    );
  }
};
export default Po;
