import React from 'react'
import { Select } from 'antd';

const MultiSelectServices = (props) => {

    const { Option } = Select;

    const children = [];
    let serviceGroup=[]
    
    props.serviceList&&props.serviceList.forEach(function(item){
      let _serviceGroup = serviceGroup.filter(x => x.value == item.service_group_id);
      if(_serviceGroup.length <= 0){
        serviceGroup.push({name:item['service_group_name.param_description'],value:item.service_group_id});
      }})
    serviceGroup && serviceGroup.map((serviceListitem) => {
        children.push(<Option value={serviceListitem.value} key={serviceListitem.value}>{serviceListitem.name}</Option>)
    })

    return (
        <div className={props.className}>
            <label htmlFor={props.label}>{props.label}</label>
            <div className="ant_form">
                <Select
                virtual={false}
                    value={props.value}
                    mode={props.ple}
                    allowClear
                    style={{
                        width: "320px",
                        borderRadius: "0px",
                        cursor: "pointer",
                        borderRadius:"3px",
                        border:"1px solid #8B8FAE",
                      }}
                    placeholder={props.pls}
                    onChange={props.handleChange}
                >
                    {children}
                </Select>
            </div>
        </div>
    )
}
export default MultiSelectServices;





