import React from 'react'
import { Select } from 'antd';

const SelectForm = (props) => {
    const { Option } = Select;

    const children = [];

    props.customerList && props.customerList.map((customerListItem) => {
        children.push(<Option value={customerListItem.clients_cust_id} key={customerListItem.clients_cust_id}>{customerListItem.name}</Option>)
    })


    return (
        <div className={props.className}>
            <label htmlFor={props.label}>{props.label}</label>
            <div className="ant_form">
                <Select
                virtual={false}
                    value={props.value}
                    mode={props.ple}
                    allowClear
                    style={{ width: '100%' }}
                    placeholder={props.pls}
                    onChange={props.handleChange}
                >
                    {children}
                </Select>
            </div>
        </div>
    )
}
export default SelectForm;





